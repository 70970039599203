// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1aB5soatsUfdKkpG3hWplD {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n._1iuy-IvS9pQPtcZk3Lb6I9 {\n    margin-right: 15px;\n    margin: 3px 15px 0 0;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/widgets/MessageWidgets.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[":local(.spinnerContainer) {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n:local(.iconMargin) {\n    margin-right: 15px;\n    margin: 3px 15px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": "_1aB5soatsUfdKkpG3hWplD",
	"iconMargin": "_1iuy-IvS9pQPtcZk3Lb6I9"
};
export default ___CSS_LOADER_EXPORT___;
