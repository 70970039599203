// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1t6lpCSJo8gXG5mJvP9TxB {\n    word-break: break-word;\n}\n\n._1t6lpCSJo8gXG5mJvP9TxB time {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/messagelist/MessageTableEntry.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe,EAAE,0BAA0B;AAC/C","sourcesContent":[":local(.fieldsRowField) {\n    word-break: break-word;\n}\n\n:local(.fieldsRowField) time {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsRowField": "_1t6lpCSJo8gXG5mJvP9TxB"
};
export default ___CSS_LOADER_EXPORT___;
