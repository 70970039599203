// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AE3SZqtk470odyvgT7_H {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n\n._1lY0VdDRhNeahTIqnZUFty {\n    margin-bottom: 10px;\n    list-style: none;\n}\n\n._32l-tpdr_v93_cvbjRGXwj {\n    margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/Value.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[":local(.dropdownheader) {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n\n:local(.bottomSpacer) {\n    margin-bottom: 10px;\n    list-style: none;\n}\n\n:local(.topSpacer) {\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownheader": "AE3SZqtk470odyvgT7_H",
	"bottomSpacer": "_1lY0VdDRhNeahTIqnZUFty",
	"topSpacer": "_32l-tpdr_v93_cvbjRGXwj"
};
export default ___CSS_LOADER_EXPORT___;
