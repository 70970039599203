// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._21jpkEaXp0FgeMq-9rzEIS {\n    float: right;\n    margin-top: 10px;\n}\n\n._3_HV2ks839vouHiXI3DHsD {\n    float: right;\n    margin-top: 10px;\n}\n\n._32o7HFy2veWs1mwTuRFtiX .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n._32o7HFy2veWs1mwTuRFtiX:not(.pajgSkVzOfqhNDw9PiruW) .description {\n    margin-left: 20px;\n}\n\n._32o7HFy2veWs1mwTuRFtiX .form-group {\n    margin-bottom: 0;\n}\n\n.pajgSkVzOfqhNDw9PiruW {\n    margin-left: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/TableList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe,EAAE,0BAA0B;IAC3C,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[":local(.headerComponentsWrapper) {\n    float: right;\n    margin-top: 10px;\n}\n\n:local(.itemActionsWrapper) {\n    float: right;\n    margin-top: 10px;\n}\n\n:local(.itemWrapper) .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n:local(.itemWrapper):not(:local(.itemWrapperStatic)) .description {\n    margin-left: 20px;\n}\n\n:local(.itemWrapper) .form-group {\n    margin-bottom: 0;\n}\n\n:local(.itemWrapperStatic) {\n    margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerComponentsWrapper": "_21jpkEaXp0FgeMq-9rzEIS",
	"itemActionsWrapper": "_3_HV2ks839vouHiXI3DHsD",
	"itemWrapper": "_32o7HFy2veWs1mwTuRFtiX",
	"itemWrapperStatic": "pajgSkVzOfqhNDw9PiruW"
};
export default ___CSS_LOADER_EXPORT___;
