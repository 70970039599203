// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2UVRs9s_TwgV_yhi8TjSFo {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/Field.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[":local(.dropdownheader) {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownheader": "_2UVRs9s_TwgV_yhi8TjSFo"
};
export default ___CSS_LOADER_EXPORT___;
